import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "react-redux";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

const ProductCard = ({
  imgUrl,
  name,
  desc,
  id,
  price,
  product,
}) => {
  const [showBtnSpinner, setShowBtnSpinner] =
    useState(false);
  const [outOfStock, setOutOfStock] =
    useState(false);
  const [activeColor, setActiveColor] =
    useState("");

  const dispatch = useDispatch();

  return (
    <div className="product-card card position-relative border-0 rounded-0">
      <div className="holder position-relative overflow-hidden">
        <Link
          to={`/product-details/${id}`}
          className="d-block"
        >
          <img
            src={imgUrl}
            alt={name}
            className="img-fluid card-img-top rounded-0"
          />
          <img
            src={imgUrl}
            alt={name}
            className="img-fluid card-img-top rounded-0 hover-img position-absolute w-80 h-80 top-0 start-0"
            style={{ borderRadius: "20px" }}
          />
        </Link>
        <button className="add-to-cart-btn btn btn-bg-white py-2 text-uppercase position-absolute fw-semibold d-flex justify-content-center align-items-center gap-2">
          <div
            className="spinner-border spinner-border-sm"
            role="status"
          >
            <span className="visually-hidden">
              Loading...
            </span>
          </div>
        </button>
        <Link to={`/product-details/${id}`}>
          <button
            className="add-to-cart-btn btn btn-bg-white py-2 text-uppercase position-absolute fw-semibold d-flex justify-content-center align-items-center gap-2"
            // onClick={() => handleAddToCart()}
          >
            <FontAwesomeIcon icon={faPlus} />{" "}
            Order Now
          </button>
        </Link>
      </div>
      <div className="card-body text-center">
        <h3 className="h6 fw-bold card-title">
          <Link
            className="d-block text-ellipsis text-decoration-none hover-color-yellow color-product-name"
            to={`/product-details/${id}`}
          >
            {name}
          </Link>
        </h3>
        <div className="card-text">
          {product.discount > 0 ? (
            <div className="d-flex align-items-center justify-content-center gap-3">
              <span className="fw-semibold">
                $
                {(
                  product.price -
                  (product.price *
                    product.discount) /
                    100
                ).toFixed(2)}
              </span>
              <span className="color-old-price text-decoration-line-through">
                UGX {price}
              </span>
            </div>
          ) : (
            <span className="fw-semibold">
              UGX {price.toLocaleString()}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
