import {
  Col,
  Container,
  Row,
} from "react-bootstrap";
import FilterSelect from "../../components/shop/filter";
import { Fragment, useState } from "react";
import { products } from "../../data";
import ShopList from "../../components/shop/ShopList";
import MayLike from "../../components/mayLike/mayLike";
// import Banner from "../components/Banner/Banner";
// import useWindowScrollToTop from "../hooks/useWindowScrollToTop";
// import MayLike from "../components/MayLike/MayLike";

const Shop = () => {
  const [filterList, setFilterList] = useState(
    products.filter(
      (item) => item.category === "other"
    )
  );
  // useWindowScrollToTop();

  return (
    <Fragment>
    <section className="filter-bar">
      <Container className="filter-bar-contianer">
        <Row className="justify-content-center">
          <Col md={2}>
            <FilterSelect setFilterList={setFilterList} />
          </Col>
          {/* <Col md={8}>
            <SearchBar setFilterList={setFilterList} />
          </Col> */}
        </Row>
      </Container>
      <Container>
        <ShopList productItems={filterList} />
      </Container>
      <MayLike />
    </section>
  </Fragment>
  );
};

export default Shop;
