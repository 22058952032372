import React from "react";
import {
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// components
import LayoutWithNav from "../components/layouts/layoutWithNav";
import LayoutWithPageHeader from "../components/layouts/layoutWithPageHeader";


// pages
import Home from "./../pages/home";
import About from "./../pages/about";
import Contact from "./../pages/contact";
import Shop from "./../pages/shop";

import Product from "../pages/Product";

import Page404 from "./../pages/page404";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<LayoutWithNav />}>
        <Route path="/" element={<Home />} />
        <Route
          path="/home"
          element={<Navigate to="/" replace />}
        />
        <Route
          path="/product-details/:id"
          element={<Product />}
        />
        <Route>
          <Route
            path="/shop"
            element={<Shop />}
          />
          <Route
            path="/about"
            element={<About />}
          />
          <Route
            path="/contact"
            element={<Contact />}
          />
        </Route>
      </Route>

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default AppRoutes;
