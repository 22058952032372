import { Col } from "react-bootstrap";
import "./product-card.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaShoppingCart } from "react-icons/fa";
// import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const ProductCard = ({ title, productItem }) => {
  //   const dispatch = useDispatch();
  const router = useNavigate();
  const handelClick = () => {
    router(`/product-details/${productItem.id}`);
  };
  //   const handelAdd = (productItem) => {
  //     dispatch(addToCart({ product: productItem, num: 1 }));
  //     toast.success("Product has been added to cart!");
  //   };
  return (
    <Col
      md={3}
      sm={5}
      xs={10}
      className="product mtop"
    >
      {title === "Big Discount" ? (
        <span className="discount">
          {productItem.discount}% Off
        </span>
      ) : null}
      <img
        loading="lazy"
        onClick={() => handelClick()}
        src={productItem.imgUrl}
        alt=""
      />
      <div className="product-like">
        <ion-icon name="heart-outline"></ion-icon>
      </div>
      <div className="product-details">
        <h3
          style={{ fontSize: "17px" }}
          onClick={() => handelClick()}
        >
          {productItem.name}
        </h3>
        <h3 onClick={() => handelClick()}>
          {productItem.desc}
        </h3>
        <div className="price">
          <h4>
            UGX{" "}
            {productItem.price.toLocaleString()}
          </h4>
          <Link
            to={`${productItem.path}${productItem.id}`}
          >
            <button
              aria-label="Add"
              type="submit"
              className="add"
              // onClick={() => handelAdd(productItem)}
            >
              <FaShoppingCart />
            </button>
          </Link>
        </div>
      </div>
    </Col>
  );
};

export default ProductCard;
