import {
  useState,
  useEffect,
  useRef,
} from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUnlock,
  faMagnifyingGlass,
  faCartShopping,
  faUser,
  faUserGear,
} from "@fortawesome/free-solid-svg-icons";
// components
// assets
import logo from "../../assets/images/logo.jpg";

const Header = ({ isMediumScreen }) => {
  const [decodedToken, setDecodedToken] =
    useState(null);
  const dispatch = useDispatch();
  const navToggleBtn = useRef();

  return (
    <header className="header sticky-lg-top bg-white">
      <nav
        className="navbar navbar-expand-lg border-bottom"
        data-bs-theme="light"
      >
        <div className="container-fluid">
          <Link to="/" className="nav-link">
            <img
              src={logo}
              alt="comfy logo"
              className="logo img-fluid"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#header-nav"
            aria-controls="header-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={navToggleBtn}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="header-nav"
          >
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <NavLink
                  onClick={() => {
                    if (
                      navToggleBtn.current.getAttribute(
                        "aria-expanded"
                      ) === "true"
                    ) {
                      navToggleBtn.current.click();
                    }
                  }}
                  to="/"
                  className="nav-link color-main-gray hover-color-yellow fs-5"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={() => {
                    if (
                      navToggleBtn.current.getAttribute(
                        "aria-expanded"
                      ) === "true"
                    ) {
                      navToggleBtn.current.click();
                    }
                  }}
                  to="/shop"
                  className="nav-link color-main-gray hover-color-yellow fs-5"
                >
                  Shop
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={() => {
                    if (
                      navToggleBtn.current.getAttribute(
                        "aria-expanded"
                      ) === "true"
                    ) {
                      navToggleBtn.current.click();
                    }
                  }}
                  to="/about"
                  className="nav-link color-main-gray hover-color-yellow fs-5"
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={() => {
                    if (
                      navToggleBtn.current.getAttribute(
                        "aria-expanded"
                      ) === "true"
                    ) {
                      navToggleBtn.current.click();
                    }
                  }}
                  className="nav-link color-main-gray hover-color-yellow fs-5"
                  to="/contact"
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
