import { useLayoutEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "react-redux";

// components
import Header from "../common/header";
import Footer from "../common/footer";
import ScrollToTopBtn from "../common/scrollToTopBtn";

const LayoutWithNav = () => {
  const [isMediumScreen, setIsMediumScreen] =
    useState(false);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    function updateSize() {
      setIsMediumScreen(
        window.innerWidth <= 991 ? true : false
      );
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () =>
      window.removeEventListener(
        "resize",
        updateSize
      );
  }, [window.innerWidth]);

  return (
    <>
      <Header
        
        isMediumScreen={isMediumScreen}
      />

      <Outlet />
      {/* <Footer /> */}
      <ScrollToTopBtn />
      {isMediumScreen}
    </>
  );
};

export default LayoutWithNav;
