import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faGithub,
  faTiktok,
  faSnapchat,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhoneVolume,
  faEnvelope,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";

import logo1 from "../../assets/images/logo.jpg";

const Footer = () => {
  const [showFollowUs, setShowFollowUs] =
    useState(false);
  const [showHereToHelp, setShowHereToHelp] =
    useState(false);
  const [
    showCustomerService,
    setShowCustomerService,
  ] = useState(false);

  useEffect(() => {
    updateStates();
    window.addEventListener(
      "resize",
      updateStates
    );
    return () => {
      window.removeEventListener(
        "resize",
        updateStates
      );
    };
  }, []);

  const updateStates = () => {
    if (window.innerWidth <= 576) {
      setShowFollowUs(false);
      setShowHereToHelp(false);
      setShowCustomerService(false);
    } else {
      setShowFollowUs(true);
      setShowHereToHelp(true);
      setShowCustomerService(true);
    }
  };

  const toggleFollowUs = () => {
    if (window.innerWidth <= 576) {
      setShowFollowUs((prevState) => !prevState);
    }
  };

  const toggleHereToHelp = () => {
    if (window.innerWidth <= 576) {
      setShowHereToHelp(
        (prevState) => !prevState
      );
    }
  };

  const toggleCustomerService = () => {
    if (window.innerWidth <= 576) {
      setShowCustomerService(
        (prevState) => !prevState
      );
    }
  };

  return (
    <footer className=" ">
      <div className="container ">
        <div className="row footer-top pb-4">
          <div className="col-lg-5 col-md-6 col-sm-12 mb-4">
            <img
              src={logo1}
              alt=""
              style={{ width: "9rem" }}
            />
            <p className="pt-4 footer-color">
              Impala Home Improvements Limited
            </p>
            <h6
              className="text-light footer-toggle d-sm-none d-flex "
              onClick={toggleFollowUs}
            >
              Follow Us{" "}
              <FontAwesomeIcon
                icon={faAngleDown}
                className={`icon ${
                  showFollowUs
                    ? "icon-rotate"
                    : ""
                } hover-color-yellow`}
              />
            </h6>{" "}
            <div
              className={`footer-content ${
                showFollowUs ? "show" : ""
              }`}
            >
              <h6 className="text-light d-none d-sm-block ">
                Follow Us
              </h6>
              <div className="social-icons">
                <Link
                  to="https://instagram.com/comfy_store4?igshid=MzNlNGNkZWQ4Mg=="
                  className="me-4 link-light  hover-color-yellow"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="lg"
                    className="pt-2 hover-color-yellow"
                  />
                </Link>
                <Link
                  to="/"
                  className="me-4 link-light"
                >
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    size="lg"
                    className="hover-color-yellow"
                  />
                </Link>
                <Link
                  to="https://www.tiktok.com/@impalahomeimprovement"
                  className="me-4 link-light"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faTiktok}
                    size="lg"
                    className="hover-color-yellow"
                  />
                </Link>
                <Link
                  to="/"
                  className="me-4 link-light"
                >
                  <FontAwesomeIcon
                    icon={faSnapchat}
                    size="lg"
                    className="hover-color-yellow"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div
              className={`footer-content ${
                showHereToHelp ? "show" : ""
              }`}
            >
              <div>
                <p>
                  <Link to="tel:+256704303968">
                    <FontAwesomeIcon
                      icon={faPhoneVolume}
                      className="text-light  pt-4 pb-2 d-block hover-color-yellow"
                      size="lg"
                    />
                  </Link>
                  <span className="text-light d-block">
                    Order by phone
                  </span>

                  <span className="contacts">
                    <Link
                      to="tel:+256701423384"
                      className=" hover-color-yellow footer-color"
                    >
                      (+256) 704 303 968
                    </Link>
                  </span>
                </p>
              </div>
              <div>
                <p>
                  <Link to="mailto:hello@domain.com">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-light  pt-4 pb-2 d-block hover-color-yellow"
                      size="lg"
                    />
                  </Link>

                  <span className="text-light d-block">
                    Email Us
                  </span>

                  <span className="contacts">
                    <Link
                      to="mailto:comfyproject20@gmail.com"
                      className=" hover-color-yellow footer-color"
                    >
                      impalahomeimprovementltd@gmail.com
                    </Link>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
            <h6
              className="text-white footer-toggle d-sm-none"
              onClick={toggleCustomerService}
            >
              Customer Service{" "}
              <FontAwesomeIcon
                icon={faAngleDown}
                className={`icon ${
                  showCustomerService
                    ? "icon-rotate"
                    : ""
                } hover-color-yellow`}
              />
            </h6>
            <h6 className="text-white d-none d-sm-block ">
              Need Help?
            </h6>

            <div
              className={`footer-content ${
                showCustomerService ? "show" : ""
              }`}
            >
              <div>
                <p className="menu-footer pt-3">
                  <Link
                    to="/contact"
                    className="footer-color link hover-color-yellow "
                  >
                    Contact Us
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="raw">
        <hr className="footer-color" />
        <div className="col-12 col-sm pt-2 pb-3 d-flex justify-content-start justify-content-sm-center text-center footer-color">
          <h6>
            © Copyright 2023 Impala Home
            Improvements Ltd. All Rights Reserved.
            Design By Andrey Sek
          </h6>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
