import trending from "./assets/images/doormain4.jpg";
import trending1 from "./assets/home-slider/wardrobe2.jpg";
import trending2 from "./assets/images/chairs.jpg";
import trending3 from "./assets/home-slider/frame.jpg";
import trending4 from "./assets/images/seat3.jpg";

import chair from "./assets/images/categorychairs.png";
import door from "./assets/images/categorydoor.png";
import frame from "./assets/images/categoryframe.png";
import sofas from "./assets/home-slider/sofas.png";
import wardrobe from "./assets/images/categorywardrobe.png";
import {
  faSnapchat,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import {
  FiFacebook,
  FiInstagram,
} from "react-icons/fi";

export const trend = [
  {
    id: 7,
    name: "Wooden Door",
    category: "door",
    desc: "Best Sellers",
    price: 750000,
    imgUrl: trending,
  },
  {
    name: "Wardrobe",
    id: 13,
    category: "wardrobe",
    desc: "Gift Sets",
    price: 750000,
    imgUrl: trending1,
  },
  {
    name: "Chairs",
    id: 35,
    category: "door",
    desc: "New",
    price: 500000,
    imgUrl: trending2,
  },
  {
    name: "Door Frame",
    id: 16,
    category: "frame",
    desc: "Luxury",
    price: 400000,
    imgUrl: trending3,
  },
  {
    name: "Seats",
    id: 37,
    category: "seat",
    desc: "Luxury",
    price: 500000,
    imgUrl: trending4,
  },
];

export const mayLike = [
  {
    id: 1,
    name: "Wooden Door",
    category: "door",
    imgUrl: require("../src/assets/home-slider/door1.jpg"),
    // imgUrl: [
    //   require("../src/assets/images/blackdoor.jpg"),
    //   require("../src/assets/images/silverdoor.jpg"),
    //   require("../src/assets/images/doormain.jpg"),
    // ],
    path: "/product-details/",
    price: 750000,
  },
  {
    name: "Wardrobe",
    id: 11,
    category: "wardrobe",
    price: 750000,
    imgUrl: wardrobe,
    // imgUrl: [
    //   require("../src/assets/home-slider/wardrobe2.jpg"),
    //   require("../src/assets/home-slider/wardrobe.jpg"),
    //   require("../src/assets/home-slider/wardrobe2.jpg"),
    // ],
    path: "/product-details/",
  },
  {
    name: "Seats",
    id: 22,
    category: "seats",
    price: 400000,
    imgUrl: require("../src/assets/images/seat2.jpg"),
    // imgUrl: [
    //   require("../src/assets/images/seat3.jpg"),
    //   require("../src/assets/images/seat2.jpg"),
    //   require("../src/assets/images/seat1.jpg"),
    // ],
    path: "/product-details/",
  },
  {
    id: 38,
    name: "Chairs",
    category: "other",
    imgUrl: require("../src/assets/images/chairs4.jpg"),
    // imgUrl: [
    //   require("../src/assets/images/blackdoor.jpg"),
    //   require("../src/assets/images/silverdoor.jpg"),
    //   require("../src/assets/images/doormain.jpg"),
    // ],
    path: "/product-details/",
    price: 750000,
    avgRating: 4.7,
  },
  {
    id: 34,
    name: "Dinning Table",
    category: "other",
    imgUrl: require("../src/assets/images/dining2.jpg"),
    // imgUrl: [
    //   require("../src/assets/images/blackdoor.jpg"),
    //   require("../src/assets/images/silverdoor.jpg"),
    //   require("../src/assets/images/doormain.jpg"),
    // ],
    path: "/product-details/",
    price: 750000,
  },
  {
    name: "Door Frame",
    id: 14,
    category: "frame",
    price: 400000,
    imgUrl: frame,
    // imgUrl: [
    //   require("../src/assets/images/framemain.jpg"),
    //   require("../src/assets/home-slider/frame.jpg"),
    //   require("../src/assets/home-slider/fra.png"),
    // ],
    path: "/product-details/",
  },
  {
    id: 30,
    name: "Dressing Mirror",
    category: "other",
    imgUrl: require("../src/assets/images/dressing3.jpg"),
    // imgUrl: [
    //   require("../src/assets/images/blackdoor.jpg"),
    //   require("../src/assets/images/silverdoor.jpg"),
    //   require("../src/assets/images/doormain.jpg"),
    // ],
    path: "/product-details/",
    price: 750000,
  },
];

export const products = [
  {
    id: 1,
    name: "Wooden Door",
    category: "door",
    imgUrl: require("../src/assets/home-slider/door1.jpg"),
    images: [
      require("../src/assets/home-slider/door5.jpg"),
      require("../src/assets/home-slider/door2.jpg"),
      require("../src/assets/home-slider/door1.jpg"),
    ],
    path: "/product-details/",
    price: 750000,
    desc: "Wooden doors made from mahogany timber"
  },
  {
    id: 2,
    name: "Wooden Door",
    category: "door",
    imgUrl: require("../src/assets/images/doormajor2.jpg"),
    images: [
      require("../src/assets/brands-categories/IMG-20240108-WA0042.jpg"),
      require("../src/assets/brands-categories/IMG-20240108-WA0041.jpg"),
      require("../src/assets/brands-categories/IMG-20240108-WA0040.jpg"),
    ],
    path: "/product-details/",
    price: 750000,
    desc: "Wooden painted doors made from mahogany timber"
  },
  {
    id: 3,
    name: "Wooden Door",
    category: "door",
    imgUrl: require("../src/assets/images/doormajor.jpg"),
    images: [
      require("../src/assets/images/doormajor4.jpg"),
      require("../src/assets/images/doormain6.jpg"),
      require("../src/assets/images/doors.jpg"),
    ],
    path: "/product-details/",
    price: 750000,
    desc: "Wooden doors made from mahogany timber"
  },
  {
    id: 4,
    name: "Bathroom Doors",
    category: "door",
    imgUrl: require("../src/assets/images/doormajor3.jpg"),
    images: [
      require("../src/assets/images/doormain2.jpg"),
      require("../src/assets/images/doormajor3.jpg"),
      require("../src/assets/images/doormain3.jpg"),
    ],
    path: "/product-details/",
    price: 750000,
    desc: "Made from mahogany and glass"
  },
  {
    id: 5,
    name: "Wooden Door",
    category: "door",
    imgUrl: require("../src/assets/images/silverdoor22.jpg"),
    images: [
      require("../src/assets/images/silverdoor22.jpg"),
      require("../src/assets/images/silverdoor2.jpg"),
      require("../src/assets/images/doormain4.jpg"),
    ],
    path: "/product-details/",
    price: 750000,
    desc: "Wooden doors made from mahogany timber"
  },
  {
    id: 6,
    name: "Wooden Door",
    category: "door",
    imgUrl: require("../src/assets/home-slider/door1.jpg"),
    images: [
      require("../src/assets/home-slider/door4.jpg"),
      require("../src/assets/home-slider/door5.jpg"),
      require("../src/assets/images/doormain5.jpg"),
    ],
    path: "/product-details/",
    price: 750000,
    desc: "Wooden doors made from mahogany timber"
  },
  {
    name: "Wardrobe",
    id: 11,
    category: "wardrobe",
    price: 750000,
    desc: "Wardrobes made from MDF Boards",
    imgUrl: require("../src/assets/brands-categories/IMG-20240108-WA0003.jpg"),
    images: [
      require("../src/assets/home-slider/wardrobe2.jpg"),
      require("../src/assets/home-slider/wardrobe.jpg"),
      require("../src/assets/brands-categories/IMG-20240108-WA0003.jpg"),
    ],
    path: "/product-details/",
  },
  {
    name: "Wardrobe",
    id: 12,
    category: "wardrobe",
    price: 750000,
    desc: "Wardrobes made from MDF Boards",
    imgUrl: require("../src/assets/home-slider/wardrobe.jpg"),
    images: [
      require("../src/assets/home-slider/wardrobe2.jpg"),
      require("../src/assets/home-slider/wardrobe.jpg"),
      require("../src/assets/home-slider/wardrobe2.jpg"),
    ],
    path: "/product-details/",
  },
  {
    name: "Wardrobe",
    id: 13,
    category: "wardrobe",
    price: 750000,
    desc: "Wardrobes made from MDF Boards",
    imgUrl: require("../src/assets/home-slider/wardrobe2.jpg"),
    images: [
      require("../src/assets/home-slider/wardrobe2.jpg"),
      require("../src/assets/home-slider/wardrobe.jpg"),
      require("../src/assets/home-slider/wardrobe2.jpg"),
    ],
    path: "/product-details/",
  },
  {
    name: "Door Frame",
    id: 14,
    category: "frame",
    price: 400000,
    desc: "Made from Mahogany timber",
    imgUrl: frame,
    images: [
      require("../src/assets/images/framemain.jpg"),
      require("../src/assets/home-slider/frame.jpg"),
      require("../src/assets/home-slider/fra.png"),
    ],
    path: "/product-details/",
  },
  {
    name: "Door Frame",
    id: 15,
    category: "frame",
    price: 400000,
    desc: "Made from Mahogany timber",
    imgUrl: require("../src/assets/images/framemain.jpg"),
    images: [
      require("../src/assets/images/framemain.jpg"),
      require("../src/assets/home-slider/frame.jpg"),
      require("../src/assets/home-slider/fra.png"),
    ],
    path: "/product-details/",
  },
  {
    name: "Door Frame",
    id: 16,
    category: "frame",
    price: 400000,
    desc: "Made from Mahogany timber",
    imgUrl: require("../src/assets/home-slider/frame.jpg"),
    images: [
      require("../src/assets/images/framemain.jpg"),
      require("../src/assets/home-slider/frame.jpg"),
      require("../src/assets/home-slider/fra.png"),
    ],
    path: "/product-details/",
  },
  {
    name: "Door Frame",
    id: 17,
    category: "frame",
    price: 400000,
    desc: "Made from Mahogany timber",
    imgUrl: require("../src/assets/home-slider/frame.jpg"),
    images: [
      require("../src/assets/images/framemain.jpg"),
      require("../src/assets/home-slider/frame.jpg"),
      require("../src/assets/home-slider/fra.png"),
    ],
    path: "/product-details/",
  },
  {
    name: "Door Frame",
    id: 18,
    category: "frame",
    price: 400000,
    desc: "Made from Mahogany timber",
    imgUrl: require("../src/assets/images/framemain.jpg"),
    images: [
      require("../src/assets/images/framemain.jpg"),
      require("../src/assets/home-slider/frame.jpg"),
      require("../src/assets/home-slider/fra.png"),
    ],
    path: "/product-details/",
  },
  {
    name: "Door Frame",
    id: 19,
    category: "frame",
    price: 400000,
    desc: "Made from Mahogany timber",
    imgUrl: require("../src/assets/images/framemain1.jpg"),
    images: [
      require("../src/assets/images/framemain.jpg"),
      require("../src/assets/home-slider/frame.jpg"),
      require("../src/assets/home-slider/fra.png"),
    ],
    path: "/product-details/",
  },
  {
    name: "School Furniture",
    id: 20,
    category: "other",
    price: 400000,
    desc: "Made from hardwood timber and steel",
    imgUrl: require("../src/assets/images/desk.jpg"),
    images: [
      require("../src/assets/images/seat3.jpg"),
      require("../src/assets/images/desk.jpg"),
      require("../src/assets/images/seat1.jpg"),
    ],
    path: "/product-details/",
  },

  {
    id: 28,
    name: "Dressing Mirror",
    category: "other",
    imgUrl: require("../src/assets/images/dressing.jpg"),
    images: [
      require("../src/assets/images/dressing2.jpg"),
      require("../src/assets/images/dressing3.jpg"),
      require("../src/assets/images/dressing.jpg"),
    ],
    path: "/product-details/",
    desc: "Made from Mahogany",
    price: 750000,
  },
  {
    id: 31,
    name: "Center Tables",
    category: "other",
    imgUrl: require("../src/assets/images/center1.jpg"),
    images: [
      require("../src/assets/images/center2.jpg"),
      require("../src/assets/images/center1.jpg"),
      require("../src/assets/images/center2.jpg"),
    ],
    path: "/product-details/",
    desc: "Made from Mahogany",
    price: 750000,
  },
  {
    id: 33,
    name: "Dinning Table",
    category: "other",
    imgUrl: require("../src/assets/images/dining.jpg"),
    images: [
      require("../src/assets/images/dining2.jpg"),
      require("../src/assets/images/dining.jpg"),
      require("../src/assets/images/dining2.jpg"),
    ],
    path: "/product-details/",
    desc: "Made from hardwood timber",
    price: 750000,
  },
  {
    id: 35,
    name: "Office Chairs",
    category: "other",
    imgUrl: require("../src/assets/images/chairs.jpg"),
    images: [
      require("../src/assets/images/chairs2.jpg"),
      require("../src/assets/images/chairs3.jpg"),
      require("../src/assets/images/chairs4.jpg"),
    ],
    path: "/product-details/",
    desc: "Made from Mahogany",
    price: 750000,
  },
  {
    id: 36,
    name: "Kitchen Cabins",
    category: "other",
    imgUrl: require("../src/assets/brands-categories/IMG-20240108-WA0004.jpg"),
    images: [
      require("../src/assets/brands-categories/IMG-20240108-WA0005.jpg"),
      require("../src/assets/brands-categories/IMG-20240108-WA0008.jpg"),
      require("../src/assets/brands-categories/IMG-20240108-WA0009.jpg"),
    ],
    path: "/product-details/",
    desc: "Made from Mahogany timber(per metre)",
    price: 1000000,
  },
];

export const Category = [
  {
    id: 1,
    name: "Office Chairs",
    imgUrl: require("../src/assets/images/chairs3.jpg"),
  },
  {
    id: 2,
    name: "Wardrobes",
    imgUrl: trending1,
  },
  {
    id: 3,
    name: "Wooden Doors",
    imgUrl: require("../src/assets/images/doormajor4.jpg"),
  },
  {
    id: 4,
    name: "Door Frames",
    imgUrl: trending3,
  },
  {
    id: 5,
    name: "School Furniture",
    imgUrl: require("../src/assets/images/seat2.jpg"),
  },
  {
    id: 6,
    name: "Dining Tables",
    imgUrl: require("../src/assets/images/dining2.jpg"),
  },
  {
    id: 7,
    name: "Sofas",
    imgUrl: sofas,
  },
];
