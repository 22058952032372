import Select from 'react-select';
import { products } from '../../data';

const options = [
    { value: "door", label: "Wooden Doors" },
    { value: "wardrobe", label: "Wardrobes" },
    { value: "frame", label: "Door Frames" },
    { value: "other", label: "Other Products" },
   
];

const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: "#373536",
        color: "white",
        borderRadius: "5px",
        border: "none",
        boxShadow: "none",
        width: "200px",
        height: "40px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#373536" : "white",
        color: state.isSelected ? "white" : "#373536",
        "&:hover": {
        backgroundColor: "#373536",
        color: "white",
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "white",
    }),
};

const FilterSelect = ({setFilterList}) => {
    const handleChange = (selectedOption)=> {
        setFilterList(products.filter(item => item.category ===selectedOption.value))
    }
    return (
    <Select
    options={options}
    defaultValue={{ value: "", label: "Categories" }}
    styles={customStyles}
    onChange={handleChange}
    />
    );
};

export default FilterSelect;