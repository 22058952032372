import React, {
    useState,
    useEffect,
  } from "react";
  import "./mayLike.css";
  import {
    AiOutlineMinus,
    AiOutlinePlus,
    AiFillStar,
    AiOutlineStar,
  } from "react-icons/ai";
  import { Link } from "react-router-dom";
  import { mayLike } from "../../data";
  
  const MayLike = () => {
    return (
      <div className="maylike-products-wrapper">
        <h4
          style={{
            color: "#78909c",
            fontFamily: "Barlow",
          }}
        >
          You may also like
        </h4>
        <Link to={"/shop"}>
          <div className="marquee">
            <div className="maylike-products-container track">
              {mayLike.map((product, i) => (
                <>
                  <div
                    className="product-card"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={product.imgUrl}
                      className="product-detail-image"
                      alt=""
                    />
                    <p
                      className="product-name"
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#78909c",
                        fontFamily: "Barlow",
                      }}
                    >
                      {product.name}
                    </p>
                  </div>
                </>
              ))}
            </div>
          </div>
        </Link>
      </div>
    );
  };
  
  export default MayLike;