import { Fragment, useEffect, useState } from "react";
// import Banner from "../components/Banner/Banner";
import { Container } from "react-bootstrap";
// import ShopList from "../components/ShopList";
import {products} from "../data"
import { useParams } from "react-router-dom";
import PropertyDetails from "./productDetails/ProductDetails";

// import useWindowScrollToTop from "../hooks/useWindowScrollToTop";

const Product = () => {
  const { id } = useParams();
  const [selectedProduct, setSelectedProduct] = useState(
    products.filter((item) => parseInt(item.id) === parseInt(id))[0]
  );
//   const [relatedProducts, setRelatedProducts] = useState([]);
//   useEffect(() => {
//     window.scrollTo(0, 0);
//     setSelectedProduct(
//       products.filter((item) => parseInt(item.id) === parseInt(id))[0]
//     );
//     setRelatedProducts(
//       products.filter(
//         (item) =>
//           item.category === selectedProduct?.category &&
//           item.id !== selectedProduct?.id
//       )
//     );
//   }, [selectedProduct, id]);

//   useWindowScrollToTop();

  return (
    <Fragment>
      <PropertyDetails selectedProduct={selectedProduct} />
      {/* <section className="related-products">
        <Container>
          <h3>You might also like</h3>
        </Container>
        <ShopList productItems={relatedProducts} />
      </section> */}
    </Fragment>
  );
};

export default Product;