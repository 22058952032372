import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

export default function TabsContainer() {
  return (
    <div className="tabsContainer mt-0">
      <Tabs
        defaultActiveKey="History"
        id="uncontrolled-tab-example"
        className="mb-3 Tabs"
        transition={true}
        fill
      >
        <Tab eventKey="History" title="About Us">
          <p className="mb-2">
            Impala Home Improvements LTD offers a
            variety of custom-made furniture and
            finishes.
          </p>
          <p className="mb-2">
            The service also includes interior
            design which aims at improving the
            ambience of your atmosphere.
          </p>
          <p className="mb-0">
            We note that you are a major
            contributor to the design process, so
            we discuss with our clients to ensure
            substantial products.
          </p>
          <p className="mb-0">
            Our range of custom-made products include, but are not limited to the following;
          </p>
          <p className="mb-0">
           Office and House Doors
          </p>
          <p className="mb-0">
           Centre Tables
          </p>
          <p className="mb-0">
           Sofas
          </p>
          <p className="mb-0">
           Wooden Wardrobes
          </p>
          <p className="mb-0">
           Office and School Furniture
          </p>
        </Tab>
      </Tabs>
    </div>
  );
}
