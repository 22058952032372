import { Container, Row } from "react-bootstrap";
import {
  Link,
  NavLink,
  useNavigate,
} from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Category } from "../../data";
import React, {
  useState,
  useEffect,
} from "react";
import CategoryCard from "./CategoryCard";

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 4, // Number of products per page
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const Categories = ({ bgColor, id }) => {
  const router = useNavigate();

  const handelClick = () => {
    router("/shop");
  };

  return (
    <section style={{ background: bgColor }}>
      <Container>
        <NavLink
          to={"/shop"}
          style={{ textDecoration: "none" }}
        >
          <div
            className="heading"
            style={{
              fontSize: "25px",
              lineHeight: "55px",

              fontFamily: "Times New Roman",
              marginTop: "40px",
              color: "#373536",
            }}
          >
            <h1>Categories</h1>
          </div>
        </NavLink>

        <Row className="justify-content-center">
          <Slider {...settings}>
            {Category?.map((product, id) => {
              return (
                <CategoryCard
                  key={product.id}
                  id={product.id}
                  imgUrl={product.imgUrl}
                  name={product.name}
                />
              );
            })}
          </Slider>
        </Row>
      </Container>
    </section>
  );
};

export default Categories;
