import { Col } from "react-bootstrap";
import "../mayLike/mayLike.css";
import {
  NavLink,
  useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { FiShoppingCart } from "react-icons/fi";

const CategoryCard = ({
  name,

  imgUrl,
  id,
}) => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const handelClick = () => {
    router("/shop");
  };

  return (
    <Col
      md={8}
      sm={5}
      xs={10}
      className="product"
    >
      <img
        loading="lazy"
        onClick={() => handelClick()}
        src={imgUrl}
        alt=""
      />

      <div className="product-details">
        <h3>{name}</h3>
      </div>
    </Col>
  );
};

export default CategoryCard;
