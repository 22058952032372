// components
import Brands from "../../components/common/brands";

import MainCarousel from "../../components/home/mainCarousel";
import Trending from "./../../components/home/trending";
import BrandCategories from "./../../components/home/brandCategories";
import Categories from "./../../components/home/categories";
import Services from "../../components/home/services";
import Footer from "../../components/common/footer";

const Home = () => {
  return (
    <div id="home">
      <MainCarousel />
      <Trending />

      <Categories />

      <Footer />
    </div>
  );
};

export default Home;
